import React, { useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { getMeterById, getOpenaiResponseForImageUrl } from '../../Redux/actions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Button, CircularProgress } from '@mui/material';
import TrendingAnalysisChart from '../TrendingAnalysisChart';
import { Lightbulb } from '@mui/icons-material';
import InsightsModal from '../Common/InsightsModal';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { generateInsightKey } from '../../utils';

export default function TrendingAnalysis({ meterId }) {

    let { id: mid, client_id: client_id } = useParams()
    const dispatch = useDispatch();

    const id = meterId ? meterId : mid

    const [previousDaysTab, setPreviousDaysTab] = useState(30);
    const [showLoader, setShowLoader] = useState( id ? true : false );
    const [chartData, setChartData] = useState([]);
    const [chartSummaryPoints, setChartSummaryPoints] = useState([]);
    const [meterProps, setMeterProps] = useState({});
    const [summaryTabs, setSummaryTabs] = useState([]);
    const [insightKey, setInsightKey] = useState(null);
    const [currentParameter, setCurrentParameter] = useState(null);

    const [openInsightModal, setOpenInsightModal] = useState(false);
    const [showInsightButton, setShowInsightButton] = useState(true)
    const [chartType, setChartType] = useState('bar');
    const chartTypesList = [
        {label: 'bar', icon: BarChartIcon}, 
        {label: 'line', icon: ShowChartIcon}
    ];
    const trendingAnalysisRef = useRef(null);

    const nPreviousDays = [
        { label: 'Today', value: 0 },
        { label: '7 - Days', value: 7 },
        { label: '30 - Days', value: 30 },
        { label: '90 - Days', value: 90 },
    ]

    useEffect(() => {
        if(id){
            fetchData();
        }
    }, [previousDaysTab])

    useEffect(() => {
        if(meterProps.pid && meterProps.meter_group && meterProps.metername && meterProps.meterid){
            handleSelectPreviousDaysTab(meterProps.pid, meterProps.meter_group, meterProps.metername, meterProps.meterid, previousDaysTab)
        }
    }, [meterProps, previousDaysTab])  

    const fetchData = () => {
        setShowLoader(true)
        dispatch(getMeterById(client_id, id)).then((resp) => {
            const meterProp = resp?.data?.meterData
            if (!meterProp) {
                setShowLoader(false)
                return;
            }
            else if(meterProp.pid === null){
                setShowLoader(false)
            }
            setMeterProps(meterProp);
        })
    }

    const handleSelectPreviousDaysTab = async (pid, meter_group, parameter_name, parameter_id, previousDays) => {
        setShowLoader(true);
        const svg_height = 108
        const svg_width = 1253
        const parameter = meter_group == 'water_balance' ? parameter_name : parameter_id
        setCurrentParameter(parameter)
        const params = {
            "pid": pid,
            'parameter': parameter,
            'meter_group': meter_group,
            'nprevious_days': previousDays,
            'svg_height': svg_height,
            'svg_width': svg_width,
            "client_id": client_id
        }
        await axios.get(`/papi/v1/get_parameter_breakup_data/`, { params })
            .then((response) => {
                if (!response) {
                    return
                }
                let data = response.data;
                if (typeof data !== 'object') {
                    try {
                        data = data.replace(/NaN/g, 'null');
                        data = JSON.parse(data);
                    } catch (error) {
                        console.error('Error parsing parameter breakup response data:', error);
                        return;
                    }
                }
                
                setChartData(data.table_data_list)
                setChartSummaryPoints(data.chart_summary_points);
                setShowLoader(false);
            })
            .catch((error) => {
                setShowLoader(false);
                console.error('Error fetching HTML:', error);
            });
    }

    const handleShowTrendingAnalysis = () => {
        if (showLoader) {
            return <div className='w3-center' style={{ marginTop: 20 }}><CircularProgress /></div>
        } else if (chartData?.length > 0) {
            return <TrendingAnalysisChart tableData={chartData} chartType={chartType}
                svgHeight={114} svgWidth={1253} npreviousDays={previousDaysTab} meterProps={meterProps} chartSummaryPoints={chartSummaryPoints}
            />
        } else if (chartData?.length == 0 || summaryTabs?.length == 0) {
            return <div className='no-data-msg'>No Data Found</div>
        }
    }

    const prepareInsightModal = () => {
        const key = generateInsightKey( `pid-${meterProps.pid}`, meterProps.meter_group, `meterid-${currentParameter}`, previousDaysTab, client_id, 'trending-analysis')
        setInsightKey(key)
        setOpenInsightModal(true);
        setShowInsightButton(false)
    }

    const handleShowTitle = () => {
        const parameterName = meterProps.metername
        let title = parameterName;
        if (meterProps?.meter_group == 'level' && /,\s?KL(D)?$/i.test(parameterName)) {
            title = parameterName.replace(/,\s?KL(D)?$/, ', Meters');
        }
        return `Trending Analysis ${title ? `- ${title}` : ''}`
    }

    return (
        <Grid>
            <Grid paddingX={0} paddingY={1} ref={trendingAnalysisRef}>
                <div className="col-md-12">
                    <div className="card" style={{padding: 20}}>
                        <div className="card-body row" style={{ overflow: 'hidden' }}>
                            <div className="col-md-6">
                                <h5 className="card-title meter-trend-barchart">
                                    {handleShowTitle()}
                                </h5>
                            </div>
                            <div className="col-md-6 float-end text-end" style={{ marginTop: 20 }}>
                                {nPreviousDays?.map((item) => (
                                    <button
                                        style={{
                                            backgroundColor: previousDaysTab === item.value ? '#215ED7' : '#6c757d',
                                            color: '#fff',
                                            marginRight: 4
                                        }}
                                        className="btn btn-secondary btn-sm tabs-nprevious-days"
                                        onClick={(e) => {
                                            
                                            setPreviousDaysTab(item.value);
                                        }}>
                                        {item.label}
                                    </button>
                                ))}
                            </div>
                            {(chartData?.length > 0 && showInsightButton) && (
                                <div style={{ margin: '10px 0px', display: 'flex', justifyContent: 'end' }}>
                                    {chartTypesList?.map((item) => (
                                        <Button
                                            id={`${item.label}-chart`}
                                            className='w3-margin-right'
                                            size='small'
                                            variant={`${chartType === item.label ? 'contained' : 'outlined'}`}
                                            onClick={() => {
                                                setChartType(item.label)
                                            }}
                                        >
                                            <item.icon color={`${chartType === item.label ? '#fff' : 'info'}`} />
                                        </Button>
                                    ))}
                                    <Button
                                        id="trending-analysis-insight"
                                        size='small'
                                        startIcon={<Lightbulb color='info' />}
                                        variant='outlined'
                                        onClick={prepareInsightModal}
                                        // onClick={() => {
                                        //     setShowInsightButton(false)
                                        //     setTimeout(() => { handleViewInsights(trendingAnalysisRef) }, 500)
                                        // }}
                                    >
                                        View Insights
                                    </Button>
                                </div>
                            )}
                            <div className="card-content col-md-12"
                                // style={{ height: '25vh' }} 
                                id="chart-trend-breakup">
                                {handleShowTrendingAnalysis()}
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
            {/* Use the InsightsModal component */}
            <InsightsModal
                openInsight={openInsightModal}
                closeInsight={()=> setOpenInsightModal(false)}
                setShowInsightButton={() => setShowInsightButton(true)}
                domRef={trendingAnalysisRef}
                insightKey={insightKey}
            />
            {/* Modal */}
        </Grid>
    );
}